// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { IEditDigitalAssets, TAvailableFund } from '../../store/reducers/digitalAssets/index.D';

const { get, post, delete: del, patch } = apiClient;
const URL = (organizationId?: string) => `organizations/${organizationId || getBrand()}/digital-assets`;

export default {
  get: (params: Record<string, any>) => get(URL(), { params }),
  getAll: (id?: string) => get(`${URL(id)}/all`),
  setAvailable: (codes: string[]) => post(`${URL()}/available`, { codes }),
  setUnavailable: (codes: string) => del(`${URL()}/unavailable?codes=${codes}`),
  editDigitalAssets: (data: IEditDigitalAssets) => patch(URL(), data),
  getAvailableExchangeFunds: (data: Pick<IEditDigitalAssets, 'codes'>) =>
    post<Array<TAvailableFund[]>>(`${URL()}/available-exchanges`, data),
};
