// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import externalAssetsService from '../../../services/externalAssets';

export default {
  getExternalAssets: createAsyncThunk(
    'externalAssets/getExternalAssets',
    async (customerId: string, thunkAPI) =>
      await storeAddErrorNotification(() => externalAssetsService.getExternalAssets(customerId), thunkAPI, false)
  ),
};
