// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { IConvertDisabledAssets } from '../../store/reducers/digitalAssets/index.D';

const { post } = apiClient;
const URL = (organizationId?: string) => `organizations/${organizationId || getBrand()}/balance-operations`;

export default {
  convertDisabledAssetTo: (data: IConvertDisabledAssets) => post(`${URL()}/convert-disabled-asset-to`, data),
};
