// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { ICreateMarginFeePayload, ReadFee } from '../../store/reducers/marginsFees/index.D';
import { ICreateMiscellaneousFeePayload } from '../../store/reducers/miscellaneousFees/index.D';
import { WithId, WithOrganizationId } from 'app/store/types';

const { post, get, patch, delete: del } = apiClient;

const URL = (organizationId?: string) => `organizations/${organizationId || getBrand()}/margins-fees`;

export default {
  create: (data: ICreateMarginFeePayload & WithOrganizationId) => post(`${URL(data.organizationId)}`, data),
  update: ({ id, organizationId, ...data }: WithId & WithOrganizationId & Partial<ICreateMarginFeePayload>) =>
    patch(`${URL(organizationId)}/${id}`, data),
  delete: (data: WithId & WithOrganizationId) => del(`${URL(data.organizationId)}/${data.id}`),
  readAccountPlatformFees: (data: ReadFee) => get(`${URL(data.organizationId)}/account`, { params: data.params }),
  readAccountConversionPair: (data: ReadFee) =>
    get(`${URL(data.organizationId)}/conversion/pair?`, { params: data.params }),
  readFiatConversionGlobal: (data: ReadFee) =>
    get(`${URL(data.organizationId)}/conversion/global`, { params: data.params }),
  readAssetFees: (data: ReadFee) => get(`${URL(data.organizationId)}/asset`, { params: data.params }),
  readAssetTradingPairFees: (data: ReadFee) =>
    get(`${URL(data.organizationId)}/traiding/pair`, { params: data.params }),
  readAssetTradingGlobalFees: (data: ReadFee) =>
    get(`${URL(data.organizationId)}/traiding/global`, { params: data.params }),
  readConversionCryptoPair: (data: ReadFee) =>
    get(`${URL(data.organizationId)}/conversion-crypto/pair`, { params: data.params }),
  readConversionCryptoGlobal: (data: ReadFee) =>
    get(`${URL(data.organizationId)}/conversion-crypto/global`, { params: data.params }),
  readTransferFees: (data: ReadFee) => get(`${URL(data.organizationId)}/transfer`, { params: data.params }),
  getMiscellaneousFees: (data: WithOrganizationId) => get(`${URL(data.organizationId)}/miscellaneous`),
  createMiscellaneousFee: ({ organizationId, ...data }: ICreateMiscellaneousFeePayload & WithOrganizationId) =>
    post(`${URL(organizationId)}/miscellaneous`, data),
  updateMiscellaneousFee: ({
    id,
    organizationId,
    ...data
  }: WithId & WithOrganizationId & Partial<ICreateMiscellaneousFeePayload>) =>
    patch(`${URL(organizationId)}/miscellaneous/${id}`, data),
  deleteMiscellaneousFee: (data: WithId & WithOrganizationId) =>
    del(`${URL(data.organizationId)}/miscellaneous/${data.id}`),
};
