import { jsPDF } from 'jspdf';
// TYPES
import { IViewDocumentData } from './index.D';

export const saveFile = (file: Blob, name: string): void => {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(file);
  a.download = name;
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(a.href);
};

export const getFileExtension = (fileName: string) => {
  const split = fileName.split('.');

  return split[split.length - 1];
};

export const bufferToBlob = (buffer: ArrayBuffer): Blob => {
  const byteArray = new Uint8Array(buffer);

  return new Blob([byteArray]);
};

export const getBase64 = (format: string, data: { type: 'Buffer'; data: number[] }) => {
  let mime = 'image/jpeg';

  if (format === 'png') mime = 'image/png';

  const buffer = Buffer.from(data.data);
  const base64 = buffer.toString('base64');

  return `data:${mime};base64,${base64}`;
};

export const savePdf = (images: IViewDocumentData[], fileName?: string): void => {
  const maxWidth = images.reduce((max, img) => (img.width > max ? img.width : max), 0);
  const maxHeight = images.reduce((max, img) => (img.height > max ? img.height : max), 0);

  const orientation = maxHeight > maxWidth ? 'portrait' : 'landscape';

  const pdf = new jsPDF({
    orientation,
    unit: 'px',
    format: [maxWidth, maxHeight],
  });

  images.forEach((img, idx) => {
    const base64 = getBase64(img.format, img.file);

    const htmlImage = new Image();
    htmlImage.src = base64;

    if (idx === 0) {
      pdf.addImage(htmlImage, 'PNG', 0, 0, img.width, img.height);
    } else {
      const page = pdf.addPage();
      page.addImage(htmlImage, 'PNG', 0, 0, img.width, img.height);
    }
  });

  const resultName = fileName || 'document.pdf';
  pdf.save(resultName);
};
