import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
import reportsService from 'app/services/reports';
import { IReportQuery, ICreateMonthlyFee, IUpdateMonthlyFee, IReportsSlice, IDownloadReportQuery } from './index.D';
import { saveFile } from 'app/helpers/saveFile';

export default {
  findLatestMonthlyFees: createAsyncThunk(
    'find/monthlyFees',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findLatestMonthlyFees(), thunkAPI, false)
  ),
  findMonthlyFees: createAsyncThunk(
    'find/monthlyFees',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findMonthlyFees(params), thunkAPI, false)
  ),
  getMonthlyFeesReport: createAsyncThunk(
    'find/monthlyFees',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getMonthlyFeesReport(params), thunkAPI, false)
  ),
  createMonthlyFees: createAsyncThunk(
    'create/monthlyFees',
    async (fee: ICreateMonthlyFee, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.createMonthlyFees(fee), thunkAPI, false)
  ),
  updateMonthlyFees: createAsyncThunk(
    'update/monthlyFees',
    async ({ id, data }: IUpdateMonthlyFee, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.updateMonthlyFees(id, data), thunkAPI, false)
  ),
  findFees: createAsyncThunk(
    'find/fees',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findFees(params), thunkAPI, false)
  ),
  findMargins: createAsyncThunk(
    'find/margins',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findMargins(params), thunkAPI, false)
  ),
  findRevenueShare: createAsyncThunk(
    'find/revenueShare',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findRevenueShare(params), thunkAPI, false)
  ),
  findPayments: createAsyncThunk(
    'find/payments',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findPayments(params), thunkAPI, false)
  ),
  findConversions: createAsyncThunk(
    'find/conversions',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findConversions(params), thunkAPI, false)
  ),
  findConversionsSell: createAsyncThunk(
    'find/conversionsSell',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(
        () => reportsService.findConversions({ ...params, sell: 'true' }),
        thunkAPI,
        false
      )
  ),
  findFunds: createAsyncThunk(
    'find/funding',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findFunds(params), thunkAPI, false)
  ),
  findCardLoads: createAsyncThunk(
    'find/cardLoads',
    async (params: IReportQuery, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.findCardLoads(params), thunkAPI, false)
  ),
  getForecastDefaults: createAsyncThunk(
    'find/forecast',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getForecastDefaults(), thunkAPI, false)
  ),

  getBusinessOverview: createAsyncThunk<IReportsSlice['businessOverview'], IReportQuery>(
    'reports/get/businessOverview',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getBusinessOverview(params), thunkAPI, false)
  ),

  getActivityRevenueAndCostSummary: createAsyncThunk<IReportsSlice['activityRevenueAndCostSummary'], IReportQuery>(
    'reports/get/activityRevenueAndCostSummary',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getActivityRevenueAndCostSummary(params), thunkAPI, false)
  ),

  getCustomerProfiling: createAsyncThunk<IReportsSlice['customerProfiling'], IReportQuery>(
    'reports/get/customerProfiling',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getCustomerProfiling(params), thunkAPI, false)
  ),

  getApprovedPartnerPerformance: createAsyncThunk<IReportsSlice['customerProfiling'], IReportQuery>(
    'reports/get/approvedPartnerPerformance',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getApprovedPartnerPerformance(params), thunkAPI, false)
  ),

  getCustomerInformation: createAsyncThunk<IReportsSlice['customerInformation'], IReportQuery>(
    'reports/get/customerInformation',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(() => reportsService.getCustomerInformation(params), thunkAPI, false)
  ),

  downloadReport: createAsyncThunk<IReportsSlice['customerProfiling'], IDownloadReportQuery>(
    'reports/get/approvedPartnerPerformance',
    async (params, thunkAPI) =>
      await storeAddErrorNotification(
        async () => {
          const response = await reportsService.downloadReport(params);

          if (response.status < 200 || response.status >= 300) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          if (!response.data) {
            throw new Error('Response body is null');
          }

          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });

          const filename = response.headers['content-disposition']
            ? response.headers['content-disposition'].split('filename=')[1]
            : `report.csv`;
          saveFile(blob, filename);

          return true;
        },
        thunkAPI,
        false
      )
  ),
};
