// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { IGlobalSettingsSlice } from 'app/store/reducers/globalSettings/index.D';

const { get, put } = apiClient;
const URL = (organizationId?: string) => `organizations/${organizationId || getBrand()}`;

export default {
  get: () => get(`${URL()}/global-settings`),
  update: (data: IGlobalSettingsSlice) => put(`${URL()}/global-settings`, data),
};
