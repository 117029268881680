// CORE
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// HELPERS
import { defaultPagination } from '../search/pagination.D';
// ACTIONS
import asyncActions from './asyncActions';
// TYPES
import {
  IDashboardSlice,
  IDashboardCustomersRisks,
  IDashboardCustomersItemChart,
  IDashboardCustomersItemCountry,
  IDashboardVolumesItemCurrency,
  IDashboardVolumesItemChart,
  IDashboardEarningsItem,
} from './index.D';

const initialState = {
  customers: {
    items: [],
    chartData: [],
    countriesData: [],
    riskData: { 1: 0, 2: 0, 3: 0 },
    pricePlanData: {},
    count: 0,
  },
  earnings: {
    transfers: {},
    items: [],
    chartData: [],
    totalAmount: 0,
    totalPending: 0,
  },
  volumes: {
    items: [],
    totalAmount: 0,
    volumesCurrency: [],
    chartData: [],
  },
  transfers: {
    items: [],
    info: defaultPagination,
    isLoading: false,
  },
} as IDashboardSlice;

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearData: (state: IDashboardSlice) => {
      state = initialState;
    },
    setCustomerRisks: (state, action: PayloadAction<IDashboardCustomersRisks>) => {
      state.customers.riskData = action.payload;
    },
    setCustomerPricePlans: (state, action: PayloadAction<Record<string, number>>) => {
      state.customers.pricePlanData = action.payload;
    },
    setCustomerChart: (state, action: PayloadAction<IDashboardCustomersItemChart[]>) => {
      state.customers.chartData = action.payload;
    },
    setCustomerCountry: (state, action: PayloadAction<IDashboardCustomersItemCountry[]>) => {
      state.customers.countriesData = action.payload;
    },
    setCustomerCount: (state, action: PayloadAction<number>) => {
      state.customers.count = action.payload;
    },
    setVolumesTotalAmount: (state, action: PayloadAction<number>) => {
      state.volumes.totalAmount = action.payload;
    },
    setVolumesByCurrency: (state, action: PayloadAction<IDashboardVolumesItemCurrency[]>) => {
      state.volumes.volumesCurrency = action.payload;
    },
    setVolumesChartData: (state, action: PayloadAction<IDashboardVolumesItemChart[]>) => {
      state.volumes.chartData = action.payload;
    },
    setEarningsChartData: (state, action: PayloadAction<IDashboardEarningsItem[]>) => {
      state.earnings.chartData = action.payload;
    },
  },
  extraReducers: {
    [asyncActions.getDashboardCustomers.fulfilled.type]: (state, action) => {
      state.customers.items = action.payload;
    },

    [asyncActions.getDashboardVolumes.fulfilled.type]: (state, action) => {
      state.volumes.items = action.payload;
    },
    [asyncActions.getDashboardTransfers.pending.type]: state => {
      state.transfers.isLoading = true;
    },
    [asyncActions.getDashboardTransfers.rejected.type]: state => {
      state.transfers.isLoading = false;
    },
    [asyncActions.getDashboardTransfers.fulfilled.type]: (state, action) => {
      state.transfers.isLoading = false;
      state.transfers.items = action.payload.items;
      state.transfers.info = action.payload.info;
    },
    [asyncActions.getDashboardEarnings.fulfilled.type]: (state, action) => {
      state.earnings.items = action.payload.items;
      state.earnings.transfers = action.payload.transfers;
      state.earnings.totalAmount = action.payload.totalAmount;
      state.earnings.totalPending = action.payload.totalPending;
    },
  },
});

export default {
  ...dashboardSlice,
  asyncActions,
};
