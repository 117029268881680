import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IPooledAssetsSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  items: [],
  isLoading: false,
} as IPooledAssetsSlice;

const pooledAssetsSlice = createSlice({
  name: 'pooledAccounts',
  initialState,
  reducers: {
    clearPooledAccounts(state: IPooledAssetsSlice) {
      state.items = [];
    },
  },
  extraReducers: {
    [asyncActions.getPooledAssets.pending.type]: (state: IPooledAssetsSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getPooledAssets.rejected.type]: (state: IPooledAssetsSlice) => {
      state.isLoading = false;
    },
    [asyncActions.getPooledAssets.fulfilled.type]: (state: IPooledAssetsSlice, action) => {
      state.items = action.payload;
      state.isLoading = false;
    },
  },
});

export default {
  ...pooledAssetsSlice,
  asyncActions,
};
