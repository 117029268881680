// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import pooledAssetsService from 'app/services/pooledAssets';


export default {
  getPooledAssets: createAsyncThunk(
    'get/pooledAssets',
    async (customerId: string, thunkAPI) =>
      await storeAddErrorNotification(() => pooledAssetsService.getPooledAssets(customerId), thunkAPI, false)
  ),
};
