import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/:urlOrgId/dashboard',
    title: 'Dashboard',
    component: lazy(() => import('components/views/Dashboard')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['dashboard_read'],
    routes: [],
  },
] as IRoutes[];
