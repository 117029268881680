import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// TYPES
import { IDigitalAssetSlice, TAvailableFund } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState: IDigitalAssetSlice = {
  items: [],
  isLoading: false,
  manageLoading: false,
  selected: {
    assets: [],
    availableConversionFunds: [],
    conversionFund: null,
  },
};

const digitalAssetsSlice = createSlice({
  name: 'digitalAssets',
  initialState,
  reducers: {
    cleatSelected: state => {
      state.selected.assets = [];
      state.selected.availableConversionFunds = [];
    },
    setSelected: (state: IDigitalAssetSlice, { payload }: PayloadAction<TAvailableFund[]>) => {
      state.selected.assets = payload;
      state.selected.availableConversionFunds = [];
    },
    setConversionFund: (state: IDigitalAssetSlice, { payload }: PayloadAction<TAvailableFund | null>) => {
      state.selected.conversionFund = payload;
    },
  },
  extraReducers: {
    [asyncActions.getDigitalAssets.fulfilled.type]: (state: IDigitalAssetSlice, action) => {
      state.items = action.payload;
    },

    [asyncActions.setDigitalAssetAvailable.pending.type]: (state: IDigitalAssetSlice) => {
      state.manageLoading = true;
    },
    [asyncActions.setDigitalAssetAvailable.rejected.type]: (state: IDigitalAssetSlice) => {
      state.manageLoading = false;
    },
    [asyncActions.setDigitalAssetAvailable.fulfilled.type]: (state: IDigitalAssetSlice, action) => {
      const codes = action.payload.codes;
      state.items = state.items.map(a => (codes?.includes(a.code) ? { ...a, available: true } : a));
      state.manageLoading = false;
    },

    [asyncActions.setDigitalAssetUnavailable.pending.type]: (state: IDigitalAssetSlice) => {
      state.manageLoading = true;
    },
    [asyncActions.setDigitalAssetUnavailable.rejected.type]: (state: IDigitalAssetSlice) => {
      state.manageLoading = false;
    },
    [asyncActions.setDigitalAssetUnavailable.fulfilled.type]: (state: IDigitalAssetSlice, action) => {
      const codes = action.payload.codes;
      state.items = state.items.map(a => (codes?.includes(a.code) ? { ...a, available: false } : a));
      state.manageLoading = false;
    },

    [asyncActions.editDigitalAssets.pending.type]: (state: IDigitalAssetSlice) => {
      state.manageLoading = true;
    },
    [asyncActions.editDigitalAssets.rejected.type]: (state: IDigitalAssetSlice) => {
      state.manageLoading = false;
    },
    [asyncActions.editDigitalAssets.fulfilled.type]: (state: IDigitalAssetSlice, action) => {
      state.manageLoading = false;
      const { codes, available, riskLevel } = action.payload;
      state.items = state.items.map(a => (codes.includes(a.code) ? { ...a, available, riskLevel } : a));
    },

    [asyncActions.getAvailableExchangeFunds.pending.type]: (state: IDigitalAssetSlice) => {
      state.manageLoading = true;
    },
    [asyncActions.getAvailableExchangeFunds.rejected.type]: (state: IDigitalAssetSlice) => {
      state.manageLoading = false;
    },
    [asyncActions.getAvailableExchangeFunds.fulfilled.type]: (
      state: IDigitalAssetSlice,
      { payload }: PayloadAction<TAvailableFund[]>
    ) => {
      state.selected.availableConversionFunds = payload;
      state.manageLoading = false;
    },
  },
});

export default {
  ...digitalAssetsSlice,
  asyncActions,
};
