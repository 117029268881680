// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICE
import globalSettings from '../../../services/globalSettings';
// TYPES
import { IGlobalSettingsSlice } from './index.D';

export default {
  getGlobalSettings: createAsyncThunk(
    'globalSetting/get',
    async (_, thunkAPI) => await storeAddErrorNotification(() => globalSettings.get(), thunkAPI, false)
  ),
  updateGlobalSettings: createAsyncThunk(
    'globalSetting/update',
    async (data: IGlobalSettingsSlice, thunkAPI) =>
      await storeAddErrorNotification(() => globalSettings.update(data), thunkAPI, false)
  ),
};
