// API
import { apiClient } from 'app/clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import {
  IWithdrawalRule,
  CreateWithdrawalRulePayload,
  UpdateWithdrawalRulePayload,
  IWithdrawalReferenceKeywords,
} from 'app/store/reducers/withdrawalRules/index.D';

const { get, post, put, delete: del } = apiClient;

const URL = () => `organizations/${getBrand()}/withdrawal-rules`;
const ReferenceURL = () => `organizations/${getBrand()}/withdrawal-reference-rules`;

export default {
  // RULES
  getRules: (): Promise<IWithdrawalRule[]> => get(URL()),
  createRule: (data: CreateWithdrawalRulePayload): Promise<IWithdrawalRule> => post(URL(), data),
  updateRule: (id: string, data: UpdateWithdrawalRulePayload): Promise<IWithdrawalRule> => put(`${URL()}/${id}`, data),
  deleteRule: (id: string): Promise<IWithdrawalRule['_id']> => del(`${URL()}/${id}`),
  // REFERENCE KEYWORDS
  getReferenceKeywords: (): Promise<IWithdrawalReferenceKeywords | null> => get(ReferenceURL()),
  updateReferenceKeywords: (data: string[]): Promise<IWithdrawalReferenceKeywords> => put(`${ReferenceURL()}`, data),
};
