// API
import { apiClient } from '../../clients/api.client';
import getBrand from '../../helpers/getBrand';
// TYPES
import {
  ICreateMonthlyFee,
  IDownloadReportQuery,
  IReportQuery,
  IReportsSlice,
} from '../../store/reducers/reports/index.D';

const { get, post, put } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  findLatestMonthlyFees: () => get(`${URL()}/monthly-fees/latest`),
  createMonthlyFees: (fee: ICreateMonthlyFee) => post(`${URL()}monthly-fees`, fee),
  updateMonthlyFees: (id: string, fee: ICreateMonthlyFee) => put(`${URL()}/monthly-fees/${id}`, fee),
  findMonthlyFees: (params?: IReportQuery) => get(`${URL()}/monthly-fees`, { params }),
  getMonthlyFeesReport: (params?: IReportQuery) => get(`${URL()}/reports/monthly-fees`, { params }),
  findMargins: (params?: IReportQuery) => get(`${URL()}/reports/margins`, { params }),
  findFees: (params?: IReportQuery) => get(`${URL()}/reports/fees`, { params }),
  findRevenueShare: (params?: IReportQuery) => get(`${URL()}/reports/revenue-share`, { params }),
  findPayments: (params?: IReportQuery) => get(`${URL()}/reports/payments`, { params }),
  findConversions: (params?: IReportQuery) => get(`${URL()}/reports/conversions`, { params }),
  findFunds: (params?: IReportQuery) => get(`${URL()}/reports/funding`, { params }),
  findCardLoads: (params?: IReportQuery) => get(`${URL()}/reports/card-loads`, { params }),
  getForecastDefaults: () => get(`${URL()}/reports/forecast-defaults`),

  getDashboardCustomers: () => get(`${URL()}/reports/customers`),
  getDashboardVolumes: (params: Record<string, unknown>) => get(`${URL()}/reports/volumes`, { params }),
  getDashboardEarnings: (params: Record<string, unknown>) => get(`${URL()}/reports/earnings`, { params }),
  getDashboardTransfers: (params: Record<string, unknown>) => get(`${URL()}/reports/transfers`, { params }),
  getForecastData: () => get(`${URL()}/reports/forecast`),
  saveForecastConfig: (data: Record<string, unknown>) => post(`${URL()}/reports/forecast`, data),

  getBusinessOverview: (params?: IReportQuery) =>
    get<IReportsSlice['businessOverview']>(`${URL()}/reports/business-overview`, { params }),

  getActivityRevenueAndCostSummary: (params?: IReportQuery) =>
    get<IReportsSlice['activityRevenueAndCostSummary']>(`${URL()}/reports/activity-revenue-and-cost-summary`, {
      params,
    }),

  getCustomerProfiling: (params?: IReportQuery) =>
    get<IReportsSlice['customerProfiling']>(`${URL()}/reports/customer-profiling`, {
      params,
    }),

  getApprovedPartnerPerformance: (params?: IReportQuery) =>
    get<IReportsSlice['approvedPartnerPerformance']>(`${URL()}/reports/approved-partner-performance`, {
      params,
    }),

  getCustomerInformation: (params?: IReportQuery) =>
    get<IReportsSlice['customerInformation']>(`${URL()}/reports/customer-information`, {
      params,
    }),

  downloadReport: (params?: IDownloadReportQuery) => get(`${URL()}/reports/download`, { params }),
};
