import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// STORE
import organizationStore from '../store/reducers/organizations';
import { useAppSelector } from './redux.hook';
// TYPES
import { orgTypes } from 'app/store/reducers/organizations/index.D';

const useGetHosts = (): null => {
  const dispatch = useDispatch();
  const admin = useAppSelector(state => state.admin.adminAuthed);
  const history = useHistory();
  const withoutAuthRoutes = ['/brands', '/'];

  useEffect(() => {
    if (!admin || withoutAuthRoutes.includes(history.location.pathname)) return;
    const allowedOrganizations = admin.organizations.filter(o => o.type !== orgTypes.master);

    const urlId = history.location.pathname.split('/')[1];
    let brand = sessionStorage.getItem('brand');

    if (brand !== urlId || !brand) {
      const organization = allowedOrganizations.find(o => o._id === urlId);

      const id = organization?._id || allowedOrganizations[0]?._id;
      if (id) {
        sessionStorage.setItem('brand', id);
        brand = id;
      }
    }

    if (brand) {
      (async () => {
        try {
          await dispatch(organizationStore.asyncActions.getOrganization(brand));
        } catch (e) {
          localStorage.removeItem('brand');
          sessionStorage.removeItem('brand');
          history.push('/brands');
        }
      })();
    }
  }, [admin]);

  return null;
};

export default useGetHosts;
