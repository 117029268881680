// CORE
import { combineReducers } from '@reduxjs/toolkit';
// REDUCERS
import auth from './auth';
import notifier from './notifier';
import customers from './customers';
import admin from './admin';
import customer from './customer';
import transactions from './transactions';
import transfers from './transfers';
import beneficiaries from './beneficiaries';
import admins from './admins';
import beneficiary from './beneficiary';
import roles from './roles';
import balances from './balances';
import riskAlerts from './riskAlerts';
import riskAlert from './riskAlert';
import currencies from './currencies';
import countries from './countries';
import organizations from './organizations';
import reports from './reports';
import notes from './notes';
import proofs from './proofs';
import business from './business';
import logs from './logs';
import digitalAssets from './digitalAssets';
import pooledAccounts from './pooledAccounts';
import externalAssets from './externalAssets';
import transactionsCrypto from './transactionsCrypto';
import dashboard from './dashboard';
import forecasting from './forecasting';
import highRiskTransactions from './highRiskTransactions';
import forecastingDax from './forecastingDax';
import manualTransactions from './manualTransactions';
import clients from './clients';
import settings from './settings';
import organizationSettings from './organizationSettings';
import jurisdictions from './jurisdictions';
import spVerifications from './spVerifications';
import riskToleranceSettings from './riskToleranceSettings';
import pricePlans from './pricePlans';
import businessActivity from './businessActivity';
import registrationRequests from './registrationRequests';
import freeMarket from './freeMarket/index';
import basePriceTiers from './basePriceTiers';
import marginsFees from './marginsFees';
import miscellaneousFees from './miscellaneousFees';
import transactionsCosts from './transactionsCosts';
import questionnaire from './questionnaire';
import announcements from './announcements';
import digitalAssetNetworks from './digitalAssetNetworks';
import fonts from './fonts';
import withdrawalRules from './withdrawalRules';
import globalSettings from './globalSettings'
import pooledAssets from './pooledAssets';

export const rootReducer = combineReducers({
  admin: admin.reducer,
  admins: admins.reducer,
  auth: auth.reducer,
  notifier: notifier.reducer,
  customers: customers.reducer,
  customer: customer.reducer,
  transactions: transactions.reducer,
  transfers: transfers.reducer,
  beneficiaries: beneficiaries.reducer,
  beneficiary: beneficiary.reducer,
  roles: roles.reducer,
  balances: balances.reducer,
  riskAlerts: riskAlerts.reducer,
  riskAlert: riskAlert.reducer,
  currencies: currencies.reducer,
  countries: countries.reducer,
  organizations: organizations.reducer,
  reports: reports.reducer,
  notes: notes.reducer,
  business: business.reducer,
  proofs: proofs.reducer,
  logs: logs.reducer,
  digitalAssets: digitalAssets.reducer,
  digitalAssetNetworks: digitalAssetNetworks.reducer,
  pooledAccounts: pooledAccounts.reducer,
  pooledAssets: pooledAssets.reducer,
  externalAssets: externalAssets.reducer,
  transactionsCrypto: transactionsCrypto.reducer,
  dashboard: dashboard.reducer,
  forecasting: forecasting.reducer,
  highRiskTransactions: highRiskTransactions.reducer,
  forecastingDax: forecastingDax.reducer,
  manualTransactions: manualTransactions.reducer,
  clients: clients.reducer,
  settings: settings.reducer,
  organizationSettings: organizationSettings.reducer,
  jurisdictions: jurisdictions.reducer,
  spVerifications: spVerifications.reducer,
  riskToleranceSettings: riskToleranceSettings.reducer,
  pricePlans: pricePlans.reducer,
  businessActivity: businessActivity.reducer,
  registrationRequests: registrationRequests.reducer,
  freeMarket: freeMarket.reducer,
  basePriceTiers: basePriceTiers.reducer,
  marginsFees: marginsFees.reducer,
  miscellaneousFees: miscellaneousFees.reducer,
  transactionsCosts: transactionsCosts.reducer,
  questionnaire: questionnaire.reducer,
  announcements: announcements.reducer,
  fonts: fonts.reducer,
  withdrawalRules: withdrawalRules.reducer,
  globalSettings: globalSettings.reducer,
});
