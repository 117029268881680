// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';

const { get } = apiClient;
const URL = () => `organizations/${getBrand()}`;

const pooledAssetsService = {
  getPooledAssets: (customerId: string): Promise<string> => get(`${URL()}/customers/${customerId}/pooled-assets`),
};

export default pooledAssetsService;
