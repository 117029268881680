// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICE
import digitalAssetsService from '../../../services/digitalAssets';
import balanceOperationsService from '../../../services/balanceOperations';
// TYPES
import { IConvertDisabledAssets, IEditDigitalAssets } from './index.D';

export default {
  getDigitalAssets: createAsyncThunk(
    'digitalAssets/get',
    async (id: string | undefined, thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.getAll(id), thunkAPI, false)
  ),
  setDigitalAssetAvailable: createAsyncThunk(
    'digitalAssets/setAvailable',
    async (codes: string[], thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.setAvailable(codes), thunkAPI, false)
  ),
  setDigitalAssetUnavailable: createAsyncThunk(
    'digitalAssets/setUnavailable',
    async (codes: string[], thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.setUnavailable(codes.join(',')), thunkAPI, false)
  ),
  editDigitalAssets: createAsyncThunk(
    'digitalAssets/edit',
    async (params: IEditDigitalAssets, thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.editDigitalAssets(params), thunkAPI, false)
  ),
  getAvailableExchangeFunds: createAsyncThunk(
    'digitalAssets/getAvailableExchangeFunds',
    async (data: Pick<IEditDigitalAssets, 'codes'>, thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetsService.getAvailableExchangeFunds(data), thunkAPI, false)
  ),
  convertDisabledAssetTo: createAsyncThunk(
    'digitalAssets/convertDisabledAssetTo',
    async (data: IConvertDisabledAssets, thunkAPI) =>
      await storeAddErrorNotification(() => balanceOperationsService.convertDisabledAssetTo(data), thunkAPI, false)
  ),
};
