// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import marginFeeService from 'app/services/marginsFees';
// TYPES
import { ICreateMiscellaneousFeePayload } from 'app/store/reducers/miscellaneousFees/index.D';
import { WithId, WithOrganizationId } from 'app/store/types';

export default {
  get: createAsyncThunk(
    'miscellaneousFees/get',
    async (data: WithOrganizationId, thunkAPI) => await storeAddErrorNotification(() => marginFeeService.getMiscellaneousFees(data), thunkAPI)
  ),
  create: createAsyncThunk(
    'miscellaneousFees/create',
    async (data: ICreateMiscellaneousFeePayload & WithOrganizationId, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.createMiscellaneousFee(data), thunkAPI)
  ),
  update: createAsyncThunk(
    'miscellaneousFees/update',
    async (data: Partial<ICreateMiscellaneousFeePayload> & WithId & WithOrganizationId , thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.updateMiscellaneousFee(data), thunkAPI)
  ),
  delete: createAsyncThunk(
    'miscellaneousFees/delete',
    async (data: WithId & WithOrganizationId, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.deleteMiscellaneousFee(data), thunkAPI)
  ),
};
