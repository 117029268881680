// CORE
import { createSlice } from '@reduxjs/toolkit';
// ASYNC ACTIONS
import asyncActions from './asyncActions';
// TYPES
import { WithdrawalRulesState } from './index.D';

const initialState: WithdrawalRulesState = {
  list: {
    items: [],
    loading: false,
  },
  loading: false,
  listReference: {
    data: null,
    loading: false,
  },
  loadingReference: false,
};

const withdrawalRulesSlice = createSlice({
  name: 'withdrawalRules',
  initialState,
  reducers: {},
  extraReducers: {
    // READ
    [asyncActions.getWithdrawalRules.pending.type]: state => {
      state.list.loading = true;
    },
    [asyncActions.getWithdrawalRules.rejected.type]: state => {
      state.list.loading = false;
    },
    [asyncActions.getWithdrawalRules.fulfilled.type]: (state, { payload }) => {
      state.list.items = payload;
      state.list.loading = false;
    },
    // CREATE
    [asyncActions.createWithdrawalRule.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.createWithdrawalRule.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.createWithdrawalRule.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.list.items.push(payload);
    },
    // UPDATE
    [asyncActions.updateWithdrawalRule.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.updateWithdrawalRule.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.updateWithdrawalRule.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.list.items = state.list.items.map(rule => {
        if (rule._id === payload._id) {
          return payload;
        }

        return rule;
      });
    },
    // DELETE
    [asyncActions.deleteWithdrawalRule.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.deleteWithdrawalRule.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.deleteWithdrawalRule.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.list.items = state.list.items.filter(rule => rule._id !== payload);
    },
    // REFERENCE KEYWORDS
    // READ
    [asyncActions.getReferenceKeywords.pending.type]: state => {
      state.listReference.loading = true;
    },
    [asyncActions.getReferenceKeywords.rejected.type]: state => {
      state.listReference.loading = false;
    },
    [asyncActions.getReferenceKeywords.fulfilled.type]: (state, { payload }) => {
      state.listReference.data = payload;
      state.listReference.loading = false;
    },
    // UPDATE
    [asyncActions.updateReferenceKeywords.pending.type]: state => {
      state.loadingReference = true;
    },
    [asyncActions.updateReferenceKeywords.rejected.type]: state => {
      state.loadingReference = false;
    },
    [asyncActions.updateReferenceKeywords.fulfilled.type]: (state, { payload }) => {
      state.loadingReference = false;
      state.listReference.data = payload;
    },
  },
});

export default {
  ...withdrawalRulesSlice,
  asyncActions,
};
