export default {
  text: {
    white: '#FFFFFF',
    black25: '#C1C2C5',
    black60: '#6B6D73',
    black: '#0f1425',
    violet: '#6715d6',
    grey: '#6f727c',
    purple: '#6711D6',
    red: '#FF0F49',
    green: '#08951E',
    orange: '#FF910F',
    blue: '#4411D6',
    secondary: '#6B6B82',
    purpleAccent: '#8552F5',
    greySecondary: '#8D8D8D',
  },
  bg: {
    border: '#E2E3E5',
    primaryDisabled: '#E7DCFD',
    statusBg: '#F3F3F3',
    graybg: '#F7F7FA',
    black: '#0f1425',
    grey: '#E5E5E5',
    grey7: '#E7EBEE',
    greyLight: '#F9F9F9',
    white: '#FFFFFF',
    violet: '#6715d6',
    violet10: '#F0E7FB',
    red: '#FF0F49',
    redBg: '#FFE7ED',
    green: '#08951E',
    greenBg: '#E6F4E9',
    purple: '#D611B6',
    purple10: '#D611B61A',
    blue: '#10BBF2',
    blue10: '#10BBF21A',
    orangeBg: '#FFF4E7',
    blueBg: '#4411D61A',
    softPurple: '#EFEBFA',
    progress: '#AB7EE8',
    completed: '#2e7d32',
    completedHover: '#1b5e20',
    review: '#FF910F',
    reviewHover: '#DF8010',
    yellow: '#FFFF00',
    brown: '#964B00',
    brightOrange: '#FFAC1C',
    pink: '#FFABD4',
    darkGrey: '#A9A9A9',
  },
};
