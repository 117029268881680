// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';

const { get } = apiClient;
const URL = () => `organizations/${getBrand()}/external-assets`;

const externalAssetsService = {
  getExternalAssets: (customerId: string) => get(`${URL()}/list/${customerId}`),
};

export default externalAssetsService;
