import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IGlobalSettingsSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState: IGlobalSettingsSlice = {
  assets: {
    multiplier: 2,
  },
};

const globalSettingsSlice = createSlice({
  name: 'globalSettings',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.getGlobalSettings.pending.type]: (state: IGlobalSettingsSlice) => {
      state.assets = initialState.assets;
    },
    [asyncActions.getGlobalSettings.rejected.type]: (state: IGlobalSettingsSlice) => {
      state.assets = initialState.assets;
    },
    [asyncActions.getGlobalSettings.fulfilled.type]: (state: IGlobalSettingsSlice, { payload }) => {
      state.assets = payload.assets;
    },

    [asyncActions.updateGlobalSettings.pending.type]: (state: IGlobalSettingsSlice) => {
      state.assets = initialState.assets;
    },
    [asyncActions.updateGlobalSettings.rejected.type]: (state: IGlobalSettingsSlice) => {
      state.assets = initialState.assets;
    },
    [asyncActions.updateGlobalSettings.fulfilled.type]: (state: IGlobalSettingsSlice, { payload }) => {
      state.assets = payload.assets;
    },
  },
});

export default {
  ...globalSettingsSlice,
  asyncActions,
};
